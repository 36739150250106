import React from "react"
import Layout from "../../components/layout"
import { Container, Row, Col, Alert } from "reactstrap"
import { StaticImage } from 'gatsby-plugin-image'
import 'bootstrap/dist/css/bootstrap.css'


let P3 = () => (
    <Layout>
        <Container className="py-5">
            <Row>
                <Col xs={12} md={3} className="mb-3">
                    <StaticImage src="../../data/product/p10.jpg" />
                    <div style="line-height: 30px;"><span style="font-size: 16px;">公路信号灯
                    </span><br style="" /></div>
                </Col>
                <Col xs={12} md={9} className="mb-9">
                    <Alert color="primary">
                        产品详情
                    </Alert>
                    <p>
                    公路信号灯是铁路道口专用信号灯，分为2联信号灯（灯板颜色为1红1黄）和3联信号灯（灯板颜色为2红1黄），其作用是通过报警设备联动报警信号，平时黄灯闪烁，火车通过时红灯闪烁。
                    </p>
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default P3





